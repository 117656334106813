import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'formError',
  standalone: true,
  pure: false,
})
export class FormErrorPipe implements PipeTransform {
  transform(
    field: AbstractControl<unknown>,
    forceError: boolean = false,
  ): '' | 'error' {
    if (forceError) {
      return 'error';
    }

    if (field.touched && field.invalid) {
      return 'error';
    }

    return '';
  }
}
